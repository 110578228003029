import { createComponent } from '@/lib/vue';

export const InternalServerErrorSvg = createComponent({
  name: 'InternalServerErrorSvg',
  setup(props, ctx) {
    return () => (
      <div id="InternalServerErrorSvg">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-full"
          viewBox="0 0 1119.60911 699"
        >
          <circle cx="292.60911" cy="213" r="213" fill="#f2f2f2" />
          <path
            fill="#2f2e41"
            d="M0 51.14237c0 77.49789 48.6181 140.20819 108.70073 140.20819"
          />
          <path
            fill="#0e7490"
            d="M108.70073 191.35056c0-78.36865 54.255-141.78356 121.30372-141.78356M39.38432 58.16768c0 73.61476 31.00285 133.18288 69.31641 133.18288"
          />
          <path
            fill="#2f2e41"
            d="M108.70073 191.35056c0-100.13772 62.7103-181.16788 140.20819-181.16788"
          />
          <path
            fill="#a8a8a8"
            d="M85.8329 192.33905s15.41555-.47479 20.06141-3.783 23.713-7.2585 24.86553-1.95278 23.16671 26.38821 5.76263 26.5286-40.43935-2.711-45.07627-5.53549-5.6133-15.25733-5.6133-15.25733z"
          />
          <path
            d="M136.83311 211.28489c-17.40408.14042-40.43933-2.71094-45.07626-5.53548-3.53126-2.151-4.93843-9.86945-5.40926-13.43043-.32607.014-.51463.02-.51463.02s.97638 12.43276 5.61331 15.2573 27.67217 5.67589 45.07626 5.53547c5.02386-.04052 6.7592-1.82793 6.66391-4.47526-.69798 1.59951-2.61404 2.59825-6.35333 2.6284z"
            opacity=".2"
          />
          <ellipse
            cx="198.60911"
            cy="424.5"
            fill="#3f3d56"
            rx="187"
            ry="25.43993"
          />
          <ellipse
            cx="198.60911"
            cy="424.5"
            opacity=".1"
            rx="157"
            ry="21.35866"
          />
          <ellipse
            cx="836.60911"
            cy="660.5"
            fill="#3f3d56"
            rx="283"
            ry="38.5"
          />
          <ellipse
            cx="310.60911"
            cy="645.5"
            fill="#3f3d56"
            rx="170"
            ry="23.12721"
          />
          <path
            fill="none"
            stroke="#2f2e41"
            stroke-miterlimit="10"
            stroke-width="2"
            d="M462.60911 626c90 23 263-30 282-90m-435-277s130-36 138 80-107 149-17 172m-246.59767 26.28332s39.0723-10.82 41.47675 24.04449-32.15951 44.78287-5.10946 51.69566"
          />
          <path
            fill="#2f2e41"
            d="M778.70465 563.23988l-7.87754 50.29517s-38.78182 20.60284-11.51335 21.20881 155.73324 0 155.73324 0 24.84461 0-14.54318-21.81478l-7.87756-52.719z"
          />
          <path
            d="M753.82817 634.19812c6.193-5.51039 16.9989-11.252 16.9989-11.252l7.87756-50.2952 113.9216.10717 7.87756 49.582c9.185 5.08711 14.8749 8.987 18.20362 11.97818 5.05882-1.15422 10.58716-5.44353-18.20362-21.38921l-7.87756-52.719-113.9216 3.02983-7.87752 50.29517s-32.58815 17.31462-16.99894 20.66306z"
            opacity=".1"
          />
          <rect
            width="513.25314"
            height="357.51989"
            x="578.43291"
            y="212.68859"
            fill="#2f2e41"
            rx="18.04568"
          />
          <path
            fill="#3f3d56"
            d="M595.70294 231.77652h478.71308v267.83694H595.70294z"
          />
          <circle cx="835.05948" cy="223.29299" r="3.02983" fill="#f2f2f2" />
          <path
            fill="#2f2e41"
            d="M1091.68605 520.82226v31.34054a18.04341 18.04341 0 01-18.04568 18.04568H596.4786a18.04341 18.04341 0 01-18.04569-18.04568v-31.34054zM968.978 667.466v6.06h-326.01v-4.848l.449-1.212 8.035-21.815h310.86l6.666 21.815zm125.45911-5.93241c-.59383 2.539-2.83591 5.21743-7.90178 7.75032-18.179 9.08949-55.1429-2.42386-55.1429-2.42386s-28.4804-4.84773-28.4804-17.573a22.72457 22.72457 0 012.49658-1.48459c7.64294-4.04351 32.98449-14.02122 77.9177.42248a18.73921 18.73921 0 018.54106 5.59715c1.82082 2.13144 3.24843 4.83506 2.56974 7.7115z"
          />
          <path
            d="M1094.43711 661.53359c-22.251 8.526-42.0843 9.1622-62.43871-4.975-10.26507-7.12617-19.59089-8.88955-26.58979-8.75618 7.64294-4.04351 32.98449-14.02122 77.9177.42248a18.73921 18.73921 0 018.54106 5.59715c1.82082 2.13149 3.24843 4.83511 2.56974 7.71155z"
            opacity=".1"
          />
          <ellipse
            cx="1066.53846"
            cy="654.13477"
            fill="#f2f2f2"
            rx="7.87756"
            ry="2.42386"
          />
          <circle cx="835.05948" cy="545.66686" r="11.51335" fill="#f2f2f2" />
          <path
            d="M968.978 667.466v6.06h-326.01v-4.848l.449-1.212h325.561z"
            opacity=".1"
          />
          <path fill="#2f2e41" d="M108.60911 159h208v242h-208z" />
          <path
            fill="#3f3d56"
            d="M87.60911 135h250v86h-250zm0 102h250v86h-250zm0 102h250v86h-250z"
          />
          <path fill="#0e7490" d="M271.60911 150h16v16h-16z" opacity=".4" />
          <path fill="#0e7490" d="M294.60911 150h16v16h-16z" opacity=".8" />
          <path fill="#0e7490" d="M317.60911 150h16v16h-16z" />
          <path fill="#0e7490" d="M271.60911 251h16v16h-16z" opacity=".4" />
          <path fill="#0e7490" d="M294.60911 251h16v16h-16z" opacity=".8" />
          <path fill="#0e7490" d="M317.60911 251h16v16h-16z" />
          <path fill="#0e7490" d="M271.60911 352h16v16h-16z" opacity=".4" />
          <path fill="#0e7490" d="M294.60911 352h16v16h-16z" opacity=".8" />
          <path fill="#0e7490" d="M317.60911 352h16v16h-16z" />
          <circle cx="316.60911" cy="538" r="79" fill="#2f2e41" />
          <path fill="#2f2e41" d="M280.60911 600h24v43h-24zm48 0h24v43h-24z" />
          <ellipse cx="300.60911" cy="643.5" fill="#2f2e41" rx="20" ry="7.5" />
          <ellipse cx="348.60911" cy="642.5" fill="#2f2e41" rx="20" ry="7.5" />
          <circle cx="318.60911" cy="518" r="27" fill="#fff" />
          <circle cx="318.60911" cy="518" r="9" fill="#3f3d56" />
          <path
            fill="#0e7490"
            d="M239.97644 464.53228c-6.37889-28.56758 14.01185-57.43392 45.544-64.47477s62.2651 10.41 68.644 38.9776-14.51861 39.10379-46.05075 46.14464-61.75836 7.92011-68.13725-20.64747z"
          />
          <ellipse
            cx="417.21511"
            cy="611.34365"
            fill="#2f2e41"
            rx="39.5"
            ry="12.40027"
            transform="rotate(-23.171 156.40763 637.65378)"
          />
          <ellipse
            cx="269.21511"
            cy="664.34365"
            fill="#2f2e41"
            rx="39.5"
            ry="12.40027"
            transform="rotate(-23.171 8.40763 690.65378)"
          />
          <path
            fill="#fff"
            d="M362.60911 561c0 7.732-19.90861 23-42 23s-43-14.268-43-22 20.90861-6 43-6 42-2.732 42 5z"
          />
        </svg>
      </div>
    );
  },
});
