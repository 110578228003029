import { InternalServerErrorSvg } from '@/components/illustrations/InternalServerErrorSvg';
import { createComponent } from '@/lib/vue';
import { ErrorPage } from '../components/ErrorPage';

export default createComponent({
  name: 'InternalServerErrorPage',
  setup(props, ctx) {
    return () => (
      <ErrorPage
        svgComponent={InternalServerErrorSvg}
        title="Algo deu errado"
        description="Já fomos alertados automaticamente sobre o problema e trabalharemos para corrigi-lo o mais rápido possível"
      />
    );
  },
});
