import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { userIsRecepcionista } from '@/store/utils/auth';

export const GoToHomeBtn = createComponent({
  name: 'GoToHomeBtn',
  setup(props, ctx) {
    let label = 'Visão Geral';

    if (userIsRecepcionista()) {
      label = 'Agenda';
    }

    return () => (
      <div class="flex">
        <v-btn large color="primary" to={Routes.app.home}>
          Voltar para {label}
        </v-btn>
      </div>
    );
  },
});
