import { createComponent } from '@/lib/vue';
import { ErrorPage } from '../components/ErrorPage';
import { NotFoundSvg } from '@/components/illustrations/NotFoundSvg';

export default createComponent({
  name: 'NotFoundPage',
  setup(props, ctx) {
    return () => (
      <ErrorPage
        svgComponent={NotFoundSvg}
        title="Não encontrado"
        description="A página ou recurso que você estava procurando não existe"
      />
    );
  },
});
