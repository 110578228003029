import { createComponent } from '@/lib/vue';
import { ITsxComponent } from '@/typings';
import { PropType } from 'vue';
import { GoToHomeBtn } from './GoToHomeBtn';

export const ErrorPage = createComponent({
  name: 'ErrorPage',
  props: {
    svgComponent: { type: Object as PropType<ITsxComponent>, required: true },
    title: { type: String, required: true },
    description: { type: String, required: true },
  },
  setup(props, ctx) {
    const SvgComponent = props.svgComponent;

    return () => (
      <div class="flex flex-col items-center justify-center">
        <SvgComponent class="w-100" />

        <div class="flex flex-col items-center justify-center mt-8">
          <div class="text-display">{props.title}</div>

          <div class="max-w-xl my-4 font-normal text-center text-title">
            {props.description}
          </div>

          <GoToHomeBtn />
        </div>
      </div>
    );
  },
});

export default ErrorPage;
