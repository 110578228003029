import { createComponent } from '@/lib/vue';

export const NotFoundSvg = createComponent({
  name: 'NotFoundSvg',
  setup(props, ctx) {
    return () => (
      <div id="NotFoundSvg">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-full"
          viewBox="0 0 1120.59226 777.91584"
        >
          <circle cx="212.59226" cy="103" r="64" fill="#ff6584" />
          <path
            fill="#f2f2f2"
            d="M523.97629 343.12173c0 151.01141-89.77389 203.73895-200.51559 203.73895s-200.51557-52.72754-200.51557-203.73895S323.4607 0 323.4607 0s200.51559 192.11032 200.51559 343.12173z"
          />
          <path
            fill="#3f3d56"
            d="M316.156 523.761l2.054-126.383 85.464-156.354-85.142 136.528.923-56.827 58.902-113.12-58.658 98.082 1.66-102.206 63.074-90.058-62.812 73.986L322.658 0l-6.52 248.096.536-10.235-64.127-98.157 63.099 117.804-5.975 114.146-.178-3.029-73.928-103.296 73.704 113.999-.747 14.275-.134.215.061 1.172-15.159 289.599h20.254l2.43-149.584 73.522-113.72-73.34 102.476z"
          />
          <path
            fill="#f2f2f2"
            d="M1120.59226 404.97159c0 123.61-73.4842 166.77-164.13156 166.77s-164.13156-43.16-164.13156-166.77S956.4607 124.1101 956.4607 124.1101s164.13156 157.25146 164.13156 280.86149z"
          />
          <path
            fill="#3f3d56"
            d="M950.482 552.833l1.68-103.45 69.957-127.983-69.693 111.754.756-46.515 48.214-92.595-48.014 80.285 1.359-83.66 51.628-73.717-51.415 60.562.85-153.404-5.337 203.078.439-8.377-52.492-80.347 51.65 96.429-4.891 93.434-.146-2.48-60.513-84.553 60.33 93.314-.612 11.685-.109.176.05.959-12.409 237.05h16.579l1.989-122.441 60.182-93.085-60.032 83.881z"
          />
          <ellipse
            cx="554.59226"
            cy="680.47903"
            fill="#3f3d56"
            rx="554.59226"
            ry="28.03433"
          />
          <ellipse
            cx="892.44491"
            cy="726.79663"
            fill="#3f3d56"
            rx="94.98858"
            ry="4.80162"
          />
          <ellipse
            cx="548.71959"
            cy="773.11422"
            fill="#3f3d56"
            rx="94.98858"
            ry="4.80162"
          />
          <ellipse
            cx="287.94432"
            cy="734.27887"
            fill="#3f3d56"
            rx="217.01436"
            ry="10.96996"
          />
          <circle cx="97.08375" cy="566.26982" r="79" fill="#2f2e41" />
          <path
            fill="#2f2e41"
            d="M60.35576 627.84135l23.99834.28277-.50664 42.99702-23.99833-.28278zM108.35243 628.4069l23.99833.28277-.50664 42.99702-23.99833-.28278z"
          />
          <ellipse
            cx="119.54569"
            cy="732.61606"
            fill="#2f2e41"
            rx="7.5"
            ry="20"
            transform="rotate(-89.325 68.81096 722.18224)"
          />
          <ellipse
            cx="167.55414"
            cy="732.18168"
            fill="#2f2e41"
            rx="7.5"
            ry="20"
            transform="rotate(-89.325 116.8194 721.74786)"
          />
          <circle cx="99.31925" cy="546.29477" r="27" fill="#fff" />
          <circle cx="99.31925" cy="546.29477" r="9" fill="#3f3d56" />
          <path
            fill="#0e7490"
            d="M21.32201 491.90428c-6.04185-28.64075 14.68758-57.26483 46.30049-63.93367s62.13813 11.14292 68.18 39.78367-14.97834 38.93-46.59124 45.59886-61.84739 7.1919-67.88925-21.44886z"
          />
          <path
            fill="#0e7490"
            d="M217.59226 610.34203c0 55.07585-32.73985 74.3063-73.13 74.3063q-1.40351 0-2.80255-.0312c-1.87139-.04011-3.72494-.1292-5.55619-.254-36.45135-2.57979-64.77127-22.79937-64.77127-74.02113 0-53.00843 67.73872-119.89612 72.827-124.84633l.00892-.00889c.19608-.19159.29409-.28516.29409-.28516s73.13 70.06457 73.13 125.14041z"
          />
          <path
            fill="#3f3d56"
            d="M141.79781 676.22274l26.747-37.37367-26.81386 41.4773-.07125 4.29076c-1.87139-.04011-3.72494-.1292-5.55619-.254l2.88282-55.10258-.0223-.42775.049-.0802.27179-5.20415-26.88076-41.5798 26.96539 37.67668.06244 1.105 2.17874-41.63324-23.0132-42.96551 23.29391 35.6583 2.26789-86.31419.00892-.294v.28516l-.37871 68.064 22.91079-26.98321-23.00435 32.84678-.60595 37.27566 21.39143-35.77416-21.4805 41.259-.33863 20.723 31.05561-49.79149-31.17146 57.023z"
          />
          <circle cx="712.48505" cy="565.41532" r="79" fill="#2f2e41" />
          <path
            fill="#2f2e41"
            d="M696.28684 635.25524l22.94107-7.05034 12.63187 41.10275-22.94107 7.05034zM742.16776 621.1593l22.94112-7.05024 12.63168 41.10284-22.94112 7.05024z"
          />
          <ellipse
            cx="767.887"
            cy="732.00275"
            fill="#2f2e41"
            rx="20"
            ry="7.5"
            transform="rotate(-17.083 544.82635 833.65554)"
          />
          <ellipse
            cx="813.47537"
            cy="716.94619"
            fill="#2f2e41"
            rx="20"
            ry="7.5"
            transform="rotate(-17.083 590.41474 818.599)"
          />
          <circle cx="708.52153" cy="545.71023" r="27" fill="#fff" />
          <circle cx="708.52153" cy="545.71023" r="9" fill="#3f3d56" />
          <path
            fill="#0e7490"
            d="M617.65139 517.70108c-14.48957-25.43323-3.47841-59.016 24.59412-75.0092s62.57592-8.34055 77.06549 17.09268-2.39072 41.6435-30.46325 57.63671-56.70679 25.71304-71.19636.27981zM571.59226 600.25667c0 50.55711-30.05368 68.20979-67.13 68.20979q-1.28835 0-2.57261-.02864c-1.71785-.03682-3.41933-.1186-5.10033-.23313-33.46068-2.36813-59.45707-20.92878-59.45707-67.948 0-48.65932 62.18106-110.05916 66.85186-114.60322l.00819-.00817c.18-.17587.27-.26177.27-.26177s67.12996 64.31603 67.12996 114.87314z"
          />
          <path
            fill="#3f3d56"
            d="M502.01642 660.73216l24.55253-34.30732-24.6139 38.07426-.0654 3.93872c-1.71785-.03682-3.41933-.1186-5.10033-.23313l2.6463-50.58165-.02047-.39266.045-.07361.24949-4.77718-24.67531-38.16836 24.753 34.58547.05731 1.01433 2-38.21741-21.12507-39.44039 21.38272 32.73269 2.08182-79.23247.00819-.26994v.26177l-.34764 62.47962 21.031-24.76934-21.11693 30.15184-.55624 34.21735 19.63634-32.839-19.71812 37.87389-.31085 19.0228 28.50763-45.70631-28.614 52.34448z"
          />
          <path
            fill="#0e7490"
            d="M835.59226 621.34203c0 55.07585-32.73985 74.3063-73.13 74.3063q-1.4035 0-2.80255-.0312c-1.87139-.04011-3.72494-.1292-5.55619-.254-36.45135-2.57979-64.77127-22.79937-64.77127-74.02113 0-53.00843 67.73872-119.89612 72.827-124.84633l.00892-.00889c.19608-.19159.29409-.28516.29409-.28516s73.13 70.06457 73.13 125.14041z"
          />
          <path
            fill="#3f3d56"
            d="M759.79781 687.22274l26.747-37.37367-26.81386 41.4773-.07125 4.29076c-1.87139-.04011-3.72494-.1292-5.55619-.254l2.88282-55.10258-.0223-.42775.049-.0802.27179-5.20415-26.88069-41.57977 26.96539 37.67668.06244 1.105 2.17874-41.63324-23.0132-42.96551 23.29391 35.6583 2.26789-86.31419.00892-.294v.28516l-.37871 68.064 22.91079-26.98321-23.00435 32.84678-.606 37.27566 21.39141-35.77419-21.4805 41.259-.33863 20.723 31.05561-49.79149-31.17146 57.023z"
          />
          <ellipse
            cx="721.51694"
            cy="656.82212"
            fill="#2f2e41"
            rx="12.40027"
            ry="39.5"
            transform="rotate(-64.626 653.4095 657.68813)"
          />
          <ellipse
            cx="112.51694"
            cy="651.82212"
            fill="#2f2e41"
            rx="12.40027"
            ry="39.5"
            transform="rotate(-68.158 47.55024 650.64525)"
          />
        </svg>
      </div>
    );
  },
});
