import { createComponent } from '@/lib/vue';
import ErrorPage from '../components/ErrorPage';
import { PermissionDeniedSvg } from '@/components/illustrations/PermissionDeniedSvg';

export default createComponent({
  name: 'PermissionDeniedPage',
  setup(props, ctx) {
    return () => (
      <ErrorPage
        svgComponent={PermissionDeniedSvg}
        title="Permissão negada"
        description="Seu usuário não acesso a esta página ou recurso"
      />
    );
  },
});
